import React from "react";
import SVG from "react-inlinesvg";
import { CartReferenceNumber } from "tsi-common-react/src/apps/checkout/CartReferenceNumber";
import { jt, t } from "ttag";

import { templates } from "@reactivated";

import logo from "../src/svg/tempur-logo-text.svg";

export const Template = (
    props: templates.CheckoutHeaderTemplate,
): React.ReactNode => {
    const secure = (
        <span
            key="secure"
            className="checkout-header__header--bold"
        >{t`secure`}</span>
    );
    const secureCheckout = jt`${secure} checkout`;
    return (
        <header className="l-full-width">
            <nav className="site-nav checkout-header">
                <div className="site-nav__content checkout-header__content l-capped-width">
                    <a
                        className="site-nav__logo-link"
                        href={props.root_page_url}
                        title={t`Tempur-Pedic Home`}
                    >
                        <SVG
                            className="site-nav__logo site-nav__logo--checkout"
                            src={logo}
                            title={t`Tempur-Pedic Home`}
                        />
                    </a>

                    <h1 className="checkout-header__header">
                        {secureCheckout}
                    </h1>

                    <div className="checkout-header__header-cart-ref">
                        <CartReferenceNumber
                            encodedBasketID={props.encoded_basket_id}
                            phoneNumber={props.support_website_phone_number}
                        />
                    </div>
                </div>
            </nav>
        </header>
    );
};
